/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.string.repeat.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Msg from 'vux/src/components/msg';
import XButton from 'vux/src/components/x-button';
export default {
  name: 'RichScan',
  components: {
    Msg: Msg,
    XButton: XButton
  },
  data: function data() {
    return {
      msgShow: false,
      title: '',
      icon: '',
      repeat: false
    };
  },
  methods: {
    cancelScan: function cancelScan() {
      this.$router.go(-(history.length - 1));
    },
    getScanResult: function getScanResult(codeStr) {
      var _this = this;

      var _that = this;

      this.$api.Home.getScanResult({
        codeStr: codeStr,
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        // this.$utils.Tools.loadHide();
        var code = response.code,
            msg = response.msg;
        _that.title = msg;

        if (+code !== _this.$code.success) {
          _that.icon = 'warn';
          _that.repeat = true;
        } else {
          _that.icon = 'success';
          _that.repeat = false;
        }
      });
    },
    dtScanCode: function dtScanCode() {
      var that = this;
      this.$dd.biz.util.scan({
        type: 'all',
        // type 为 all、qrCode、barCode，默认是all。
        onSuccess: function onSuccess(data) {
          if (data.text) {
            that.getScanResult(data.text);
            that.msgShow = true;
          } else {
            that.$router.go(-(history.length - 1));
          }
        },
        onFail: function onFail() {
          this.icon = 'warn';
          this.repeat = true;
          this.title = '扫码异常';
        }
      });
    },
    wxScanQRCode: function wxScanQRCode() {
      var _this2 = this;

      var _that = this;

      this.$utils.WeiXin.wxScanQRCode(function (data) {
        if (data.code !== _this2.$code.success) {
          if (data.code === _this2.$code.cancel) {
            _this2.$router.go(-(history.length - 1));
          } else {
            _that.icon = 'warn';
            _that.repeat = true;
            _that.title = data.codeStr;
          }
        } else {
          _this2.getScanResult(data.codeStr);
        }

        _that.msgShow = true;
      });
    },
    scanCode: function scanCode() {
      var _this3 = this;

      this.$utils.Tools.getEnv(function (res) {
        if (res === 0 || res === 1) {
          _this3.wxScanCode();
        } else {
          _this3.dtScanCode();
        }
      });
    },
    wxScanCode: function wxScanCode() {
      var _this4 = this;

      this.msgShow = false;
      this.$utils.Tools.loadShow();
      var localUrl = window.location.href.split('#')[0];
      this.$api.Sys.getConfig(localUrl, this.$params.mode).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        _this4.$utils.Tools.loadHide();

        if (+code !== _this4.$code.success) {
          _this4.$utils.Tools.toastShow(msg);
        } else {
          _this4.$utils.WeiXin.wxReady(_this4.wxScanQRCode);

          _this4.$utils.WeiXin.wxConfig(data);
        }
      }).catch(function () {
        _this4.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {
    var _this5 = this;

    this.$utils.Tools.getEnv(function (res) {
      if (res === 0 || res === 1) {
        _this5.wxScanCode();
      } else {
        _this5.dtScanCode();
      }
    });
  },
  mounted: function mounted() {}
};